<template>
  <div class="login_bg">
    <v-container style="height: 100vh">
      <v-row align="center" justify="center" class="fill-height">
        <v-col cols="12" lg="5" sm="6" xl="4" class="px-lg-15 px-xl-16">
          <v-card tile color="white" class="mx-xl-10">
            <v-card-title class="py-4">
              <v-spacer></v-spacer>
              <v-avatar height="120" width="120" class="mt-n15">
                <img src="favicon.ico" alt="yelagiriguide">
              </v-avatar>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pt-1 pb-0 px-6">
              <v-form ref="loginForm">
                <p class="subtitle-2 font-weight-bold ma-0 my-1 grey--text text--darken-3"> Username: </p>
                <v-text-field placeholder="username" outlined dense hide-details v-model="userObj.username">
                  <template #append>
                    <v-icon color="grey darken-3"> mdi-account </v-icon>
                  </template>
                </v-text-field>
                <p class="subtitle-2 font-weight-bold ma-0 my-1 grey--text text--darken-3"> Password: </p>
                <v-text-field placeholder="******" outlined dense hide-details v-model="userObj.password"
                @keypress.enter="loginHandler" :type="userObj.passwordType ? 'password' : 'text'">
                  <template #append>
                    <v-icon @click.stop="userObj.passwordType = !userObj.passwordType" :color="userObj.passwordType ? 'grey darken-3' : 'primary'">
                      mdi-eye
                    </v-icon>
                  </template>
                </v-text-field>
                <v-checkbox hide-details dense v-model="rememberMe" label="Remember me" class="d-inline-flex"></v-checkbox>
              </v-form>
            </v-card-text>
            <v-card-actions class="pa-5">
              <v-spacer></v-spacer>
              <v-btn style="border: 1px solid white" dark class="px-4" @click="loginHandler" elevation="2"> Login </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { api } from '../plugins/axios'
export default {
  data () {
    return {
      userObj: {
        passwordType: true
      },
      rememberMe: false
    }
  },
  beforeMount () {
    const rememberMe = this.$cookie.get(process.env.VUE_APP_PORTAL_YG_REMEMBER_ME)
    if (rememberMe) this.rememberMe = JSON.parse(rememberMe) || false
    if (this.rememberMe) {
      const { username } = JSON.parse(this.$cookie.get(process.env.VUE_APP_PORTAL_YG_USER))
      this.userObj = { username, passwordType: true }
    }
  },
  methods: {
    loginHandler () {
      if (this.$refs.loginForm.validate()) {
        this.$_execute('post', 'login', this.userObj)
          .then(({ data } = {}) => {
            data.rememberMe = this.rememberMe
            this.$store.dispatch('login', data)
            api.defaults.headers.common['x-yg-token'] = data.token

            if (this.userDetails.role !== 2) this.$router.push('/dashboard')
            else this.$router.push('/bookings')

            this.$root.$emit('snackbar', { color: 'success', text: 'Login successful!' })
          })
      }
    }
  }
}
</script>
<style>
  .login_bg {
    background: linear-gradient(to bottom, #202020 50%, #f8f9fb 50%);
  }
</style>
